
import { mapState } from 'vuex';
import InspectionListItemForm from "./InspectionListItemForm.vue";

export default {
  name: "InspectionListItem",
  components: {
    InspectionListItemForm,
  },
  props: {
    inspection: Object,
  },
  data: () => ({
    isRemoveConfirmationNeeded: false,
    isEditActive: false,
    isLoading: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'removeInspectionsResult',
      'removeInspectionsIsLoading',
      'removeInspectionsError',
    ]),
  },
  methods: {
    remove() {
      this.isRemoveConfirmationNeeded = true;
    },
    cancelRemove() {
      this.isRemoveConfirmationNeeded = false;
    },
    requestRemove() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.$store.dispatch("inspections__new/removeInspectionsItem", {
        id: this.inspection.id,
        onSuccess: () => {
          this.$store.dispatch("inspections__new/getInspections");
        },
      });
    },
    edit() {
      this.isEditActive = !this.isEditActive;
    },
    cancelEdit() {
      this.isEditActive = false;
    },
  },
}
