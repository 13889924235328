
import { mapState } from 'vuex';
import Loader from '../Loader.vue';

export default {
  name: "InspectionListItemForm",
  components: { Loader },
  props: {
    inspection: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    title: '', // (text input)
    content: '', // (text area)
    asset: '', // (select)
    organization: '', // (select)
    owner: '', // (select)
    status: '', // (select)

    isTouched: false,

    statusOptions: [
      { value: '', title: 'Select Status', isDisabled: true },
      { value: 'Completed', title: 'Completed' },
    ],
  }),
  computed: {
    ...mapState('inspections__new', [
      'addInspectionsItemResult',
      'addInspectionsItemIsLoading',
      'addInspectionsItemError',

      'updateInspectionsItemResult',
      'updateInspectionsItemIsLoading',
      'updateInspectionsItemError',
    ]),
    ...mapState('organizations', [
      'getOrganizationsResult',
      'getOrganizationsIsLoading',
      'getOrganizationsError',
    ]),
    ...mapState('assets__new', [
      'getAssetsResult',
      'getAssetsIsLoading',
      'getAssetsError',
    ]),
    ...mapState('users', [
      'getUsersResult',
      'getUsersIsLoading',
      'getUsersError',
    ]),
    isTitleValid() {
      return !!this.title;
    },
    isContentValid() {
      return !!this.content;
    },
    isStatusValid() {
      return !!this.status;
    },
    isAssetValid() {
      return !!this.asset;
    },
    isOwnerValid() {
      return !!this.owner;
    },
    isOrganizationValid() {
      return !!this.organization;
    },
    isAllValid() {
      return this.isTitleValid && this.isContentValid && this.isStatusValid && this.isAssetValid && this.isOwnerValid && this.isOrganizationValid;
    },
    isDisabled() {
      return !this.isAllValid || this.isLoading;
    },
    isLoading() {
      return this.addInspectionsItemIsLoading ||
        this.updateInspectionsItemIsLoading ||
        this.getOrganizationsIsLoading ||
        this.getAssetsIsLoading ||
        this.getUsersIsLoading;
    },
    organizationsOptions() {
      const result = [{
        title: 'Select Organization',
        value: '',
        isDisabled: true,
      }];
      if (!this.getOrganizationsResult) {
        return result;
      }
      return result.concat(this.getOrganizationsResult.map(org => ({
        title: org.name,
        value: org.id.toString(),
      })));
    },
    assetsOptions() {
      const result = [{
        title: 'Select Asset',
        value: '',
        isDisabled: true,
      }];
      if (!this.getAssetsResult) {
        return result;
      }
      return result.concat(this.getAssetsResult.map(x => ({
        title: x.title,
        value: x.id.toString(),
      })));
    },
    ownersOptions() {
      const result = [{
        title: 'Select Owner',
        value: '',
        isDisabled: true,
      }];
      if (!this.getUsersResult) {
        return result;
      }
      return result.concat(this.getUsersResult.map(x => ({
        title: [ x.first_name, x.last_name ].filter(x => !!x).join(' ') || '[' + x.id + '] ' + x.email,
        value: x.id.toString(),
      })));
    },
  },
  methods: {
    validate() {
      this.isTouched = true;
      return this.isAllValid;
    },
    onSubmit() {
      if (!this.validate()) {
        return;
      }
      this.isTouched = false;
      const data = {
        title: this.title,
        content: this.content,
        asset: this.asset,
        organization: this.organization,
        owner: this.owner,
        status: this.status,
      };
      this.$store.dispatch(this.inspection ? 'inspections__new/updateInspectionsItem' : 'inspections__new/addInspectionsItem', {
        data: JSON.stringify(data),
        id: this.inspection ? this.inspection.id : null,
        onSuccess: () => {
          this.$store.dispatch('inspections__new/getInspections');
          this.$emit('cancel');
        },
      });
    },
  },
  beforeMount() {
    if (this.inspection) {
      this.content = this.inspection.content;
      this.title = this.inspection.title;
    }
    this.status = this.inspection ? this.inspection.status : this.statusOptions[0].value;

    const setOrganization = () => {
      this.organization = this.inspection ? this.inspection.organization.toString() : this.organizationsOptions[0].value;
    };
    if (!this.getOrganizationsResult && !this.getOrganizationsIsLoading) {
      this.$store.dispatch('organizations/getOrganizations', {
        onSuccess: setOrganization,
      });
    } else {
      setOrganization();
    }

    const setAsset = () => {
      this.asset = this.inspection ? this.inspection.asset.toString() : this.assetsOptions[0].value;
    };
    if (!this.getAssetsResult && !this.getAssetsIsLoading) {
      this.$store.dispatch('assets__new/getAssets', {
        onSuccess: setAsset,
      });
    } else {
      setAsset();
    }

    const setOwner = () => {
      this.owner = this.inspection ? this.inspection.owner.toString() : this.ownersOptions[0].value;
    };
    if (!this.getUsersResult && !this.getUsersIsLoading) {
      this.$store.dispatch('users/getUsers', {
        onSuccess: setOwner,
      });
    } else {
      setOwner();
    }
  },
}
