
import { mapState } from 'vuex';
import CommonSearchField from "../common/SearchField.vue";
import InspectionListItem from "./InspectionListItem.vue";
import InspectionListItemForm from "./InspectionListItemForm.vue";
import Loader from "../Loader.vue";

export default {
  name: "InspectionList",
  components: {
    CommonSearchField,
    InspectionListItem,
    InspectionListItemForm,
    Loader,
  },
  props: {
    widgetClasses: String,
    givenInspections: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    isAddingActive: false,
    searchQuery: null,
  }),
  computed: {
    ...mapState('inspections__new', [
      'getInspectionsResult',
      'getInspectionsIsLoading',
      'getInspectionsError',

      'removeInspectionsItemResult',
      'removeInspectionsItemIsLoading',
      'removeInspectionsItemError',
    ]),
    isLoading() {
      return (!this.givenInspections && this.getInspectionsIsLoading) || this.removeInspectionsItemIsLoading;
    },
    inspections() {
      if (!this.givenInspections && !this.getInspectionsResult) {
        return [];
      }
      if (!this.searchQuery) {
        return this.givenInspections || this.getInspectionsResult;
      }
      return (this.givenInspections || this.getInspectionsResult).filter((inspection) => {
        return inspection.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          inspection.id.toString().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    toggleAdd() {
      this.isAddingActive = !this.isAddingActive;
    },
    cancelAdd() {
      this.isAddingActive = false;
    },
    setSearchQuery(query) {
      if (!query) {
        this.searchQuery = null;
      } else {
        this.searchQuery = query;
      }
    },
  },
}
