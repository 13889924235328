
export default {
    name: 'common-search-field',
    data: () => ({
      text: '',
      delay: 600, // ms
      timeout: null,
      isTouched: false,
    }),
    methods: {
      clear() {
        this.isTouched = false;
        this.text = '';
        this.$emit('update', this.text);
      },
      debouncedInput() {
        if (!this.isTouched) {
          this.isTouched = true;
        }
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }
        this.timeout = setTimeout(() => {
          this.$emit('update', this.text);
          clearTimeout(this.timeout);
          this.timeout = null;
        }, this.delay);
      },
    },
};
